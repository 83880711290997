import {configureStore} from "@reduxjs/toolkit";


import users from './reducers/user_reducer'
import reports from './reducers/report_reducer';

export default configureStore({
    reducer : {
        users,
        reports
    }
})