import * as React from 'react';
import {useState} from 'react';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Orders from './Orders';
import BarAnimation from '../util/LineChart';
import CustomDrawer from "../util/Drawer";
import CustomAppBar from "../util/AppBar";
import PieCharts from "../util/PieChart";
// import Top5Regions from "../util/Top5Regions";
import RegionalCharts from "../util/RegionalCharts";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        www.ega.go.tz
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();


export default function Dashboard() {
    const [open, setOpen] = useState(true);


    const toggleDrawer = () => {
        setOpen(!open);
    };



    return (
        <ThemeProvider theme={defaultTheme}>
                <Box sx={{ display: 'flex' }}>
                    <CssBaseline />
            <CustomAppBar open={open} toggleDrawer={toggleDrawer}  />
            <CustomDrawer open={open} toggleDrawer={toggleDrawer} />
            {/* ... (rest of your code) */}

            <Box
                      component="main"
                      sx={{
                        backgroundColor: (theme) =>
                          theme.palette.mode === 'light'
                            ? theme.palette.grey[100]
                            : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                      }}
                    >
                      <Toolbar />
                      <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>
                          {/* Chart */}
                          <Grid item xs={12} md={7} lg={7}>
                            <Paper
                              sx={{
                                p: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                height: 290,
                              }}
                            >
                              <BarAnimation   />
                            </Paper>
                          </Grid>
                          {/* Recent Deposits */}
                          <Grid item xs={12} md={5} lg={5} mt={1}>
                            <Paper
                              sx={{
                                p: 1,
                                // display: 'flex',
                                // flexDirection: 'row',
                                height: 290,
                                  marginTop : -1,
                                  marginLeft : -1
                              }}
                            >
                              <PieCharts />
                            </Paper>
                          </Grid>
                          {/* Recent Orders */}

                          <Grid item xs={12} md={5} lg={5} >
                            <Paper sx={{ p: 1, display: 'flex', flexDirection: 'column' }}>
                              <Orders />
                            </Paper>
                          </Grid>

                            <Grid item xs={12} md={7} lg={7}>
                                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                    <RegionalCharts />
                                </Paper>
                            </Grid>

                        </Grid>
                        <Copyright sx={{ pt: 4 }} />
                      </Container>
                    </Box>
                </Box>
        </ThemeProvider>
    );
}

