import axios from 'axios';
import {createAsyncThunk} from "@reduxjs/toolkit";
import {AUTH_URL, BASE_URL} from "../URL";


axios.defaults.headers.post['Content-Type'] = "application/json";

const USERS_API = axios.create({baseURL : BASE_URL});
USERS_API.interceptors.request.use((req) =>{
    const storage =  sessionStorage.getItem("mripoti-token");
    const {data}  =  JSON.parse(storage);
    const {token}  = data;

    if(token != null){
        req.headers.Authorization = `Bearer ${token}`;
    }
    return req;
})


export const loginUser = createAsyncThunk('login', async (values) => {
    // console.log(values);
    try {
         const response =  await axios.post(`${AUTH_URL}login`, {
             email : values.email,
             password :  values.password
         });


        const data = { data :response.data};
        // console.log(data)
        sessionStorage.setItem("mripoti-token", JSON.stringify(data))

        return response.data;
    }
    catch (error){
        console.log(error);
        return error.message
    }
})



export const  registerUser = createAsyncThunk('register', async (values) => {
     try {
          const response = await USERS_API.post(`management/account/create-user`, {
              firstName : values.firstName,
              middleName : values.middleName,
              lastName : values.lastName,
              phoneNumber : values.phone,
              email : values.email,
              userRole : values.role
          });

          // console.log(response.data);

          return  response.data;
     }
     catch (error){
         console.log(error);
         return error.message()
     }
})

export const getAllUsers = createAsyncThunk("users", async ()  => {
    try {
        const response =  await USERS_API.get("management/account/all-users");

        // console.log(response.data);
        return response.data
    }
    catch (error){
        console.log(error);
        return error.message
    }
})

export const   changePassword = createAsyncThunk("pinchange", async(values) => {
    try {
         const response =  USERS_API.put("management/account/update-password", {
             newPassword : values.newPassword,
             oldPassword : values.oldPassword,
             confirmPassword : values.confirmPassword
         });

         console.log(response);
         return response;
    }
    catch (error){
        console.log(error)
        return error.message
    }
})