import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import SignIn from "./SignIn";
import bg_image from "../assets/icons/emblem.png";
import bg_image2 from '../assets/images/nature2.jpg';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://mui.com/">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const defaultTheme = createTheme();

export default function SignInSide() {

    return (
        <ThemeProvider theme={defaultTheme}>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
                {/* Left column with background image */}

                <Grid
                item
                xs={12} // Full width for smaller screens
                sm={7}  // Takes 6 columns on small screens
                md={7}  // Takes 6 columns on medium screens
                sx={{
                    backgroundImage: `url(${bg_image2})`, // Use the background image
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    display: 'flex',
                    alignItems: 'center', // Vertically center content
                    justifyContent: 'center', // Horizontally center content
                }}
            >
                <div
                    style={{
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {/* Blue overlay */}
                    <div
                        style={{
                            width: '100%', // Adjust the width of the overlay
                            height: '100%', // Adjust the height of the overlay
                            background: 'rgba(10, 20, 200, 0.6)',
                            display:'grid',
                            rowGap:'1px',
                            justifyItems:'center',// Blue background with opacity
                            position: 'relative', // Position relative for absolute positioning of the cards
                        }}
                    >
                        <div
                            style={{
                                width: '100%', // Adjust the width of the overlay
                                height: '100%', // Adjust the height of the overlay
                                display:'grid',paddingTop:'150px',
                                justifyItems:'center',// Blue background with opacity
                                position: 'relative', // Position relative for absolute positioning of the cards
                            }}>
                            <img src={bg_image} width={'150px'} height={'150px'} />
                        </div>

                       <div
                           style={{
                               width: '100%', // Adjust the width of the overlay
                               height: '100%',  // Blue background with opacity
                               display: 'flex',
                               marginTop:'-8rem',
                               justifyContent: 'center',
                               alignItems: 'center',
                               position: 'relative', // Position relative for absolute positioning of the cards
                           }}>
                           {/* Cards */}
                           <Card sx={{ minWidth: 250, margin: 2 }}>
                               <CardContent>
                                   <Typography variant="h6" component="div"  sx={{ color: '#0766AD', fontWeight: 'bold', mb:1 }}>
                                       Detect Scam Messages
                                   </Typography>
                                   <Typography variant="body2">
                                       This app detects scam messages and alerts users about them.
                                   </Typography>
                               </CardContent>
                           </Card>

                           <Card sx={{ minWidth: 250, margin: 2 }}>
                               <CardContent>
                                   <Typography variant="h6" component="div"  sx={{ color: '#0766AD', fontWeight: 'bold', mb: 1 }}>
                                       Report Messages
                                   </Typography>
                                   <Typography variant="body2">
                                       Users can report scam messages sent to them using this app
                                   </Typography>
                               </CardContent>
                           </Card>

                           <Card sx={{ minWidth: 250, margin: 2 }}>
                               <CardContent>
                                   <Typography variant="h6" component="div"  sx={{ color: '#0766AD', fontWeight: 'bold', mb : 1 }}>
                                       Provide Analytics
                                   </Typography>
                                   <Typography variant="body2">
                                       Analytics of reported messages are provided yearly and regionwise.
                                   </Typography>
                               </CardContent>
                           </Card>
                       </div>
                    </div>
                </div>
            </Grid>



            {/* Right column with SignIn component */}
                <Grid
                    item
                    xs={12} // Full width for smaller screens
                    sm={5}  // Takes 6 columns on small screens
                    md={5}  // Takes 6 columns on medium screens
                    sx={{
                        display: 'flex',
                        alignItems: 'center', // Vertically center content
                        justifyContent: 'center', // Horizontally center content
                    }}
                >
                    <SignIn />
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}
