// AppBar.js
import React, {useState} from 'react';
import {styled} from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import {Person2Rounded} from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from "@mui/material/Typography";
import MuiDrawer from "@mui/material/Drawer";

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
            backgroundColor: '#1769aa', // Set the background color to navy blue
            color: 'white', // Set text and icons color to white
        },
    })
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer -1 ,
    marginLeft: open ? drawerWidth : 1,  // Adjust the margin-left property
    width: open ? `calc(100% - ${drawerWidth}px)` : '100%',  // Adjust the width property
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));


const CustomAppBar = ({ open, toggleDrawer }) => {

    const [currentUser , setCurrentUser] = useState("");

    const storage = sessionStorage.getItem("mripoti-token");
    const {data}  = JSON.parse(storage);


    return (
        <AppBar position="absolute" open={open}>
            <Toolbar
                sx={{
                    pr: '32px',
                    ml:"20px",
                    backgroundColor: 'white',
                    color: '#1769aa',
                    width: '100%',  // Set the width to 80%
                    marginLeft: 'auto',  // Align to the right
                    // keep right padding when drawer closed
                }}
            >
                <IconButton
                    edge="start"
                    color="inherit"
                    ml={8}
                    aria-label="open drawer"
                    onClick={toggleDrawer}
                    sx={{
                        marginRight: '36px',
                        ...(open && { display: 'none'}),

                    }}
                >
                    <MenuIcon />
                </IconButton>
                <Typography
                    component="h1"
                    variant="h6"
                    // ml={8}

                    color="inherit"
                    noWrap
                    sx={{ flexGrow: 1 }}
                >
                    Dashboard
                </Typography>
                {
                    data && data.username &&(
                                <Typography
                                    component="p"
                                    variant=""
                                    color="inherit"
                                    noWrap
                                    sx={{  }}
                                >
                                    {data.username}
                                </Typography>
                    )
                }
                <IconButton color="inherit">
                    {/* <Badge badgeContent={4} color="secondary"> */}
                    <Person2Rounded />
                    {/* </Badge> */}
                </IconButton>
                {/* ... (rest of your AppBar content) */}
            </Toolbar>
        </AppBar>
    );
};

export default CustomAppBar;
