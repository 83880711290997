import React, {useEffect, useState} from 'react';
import Chart from 'react-apexcharts';
import {useDispatch, useSelector} from 'react-redux';
import {summaryByComments, userCommentReport} from '../store/actions/report_actions';
import {useNavigate} from 'react-router-dom';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AF19FF'];
const size = {
    width: 380,
    height: 280,
};

const ApexPieChart = () => {
    const [reload, setReload] = useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const pie_chart = useSelector((state) => state.reports);

    useEffect(() => {
        if (pie_chart && pie_chart.by_comments && pie_chart.by_comments.length < 1 && reload <= 2) {
            dispatch(summaryByComments());
            setReload((prevReload) => prevReload + 1);
        }
    }, [reload, pie_chart, dispatch]);

    const changePage = (label) => {

        dispatch(userCommentReport(label))
        navigate('/by-comments',{state : {label}})
    }

    const formattedReports = pie_chart?.by_comments || { comment: [], count: [] };

    // Check if formattedReports.comment is an array before mapping
    const seriesData = Array.isArray(formattedReports.comment)
        ? formattedReports.comment.map((comment, index) => formattedReports.count?.[index])
        : [];

    const options = {
        chart: {
            events: {
                dataPointSelection: (event, chartContext, config) => {

                    if (config && config.dataPointIndex != null && formattedReports.comment) {
                        const selectedDataPoint = config.dataPointIndex;
                        const selectedLabel = formattedReports.comment[selectedDataPoint];

                        // console.log('LABEL :', selectedLabel);
                        setTimeout(() => {
                            changePage(selectedLabel)
                        }, 500);
                    }

                    }
                },

        },
        labels: formattedReports.comment || [],
        legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'center',
            fontSize: '10px',
            markers: {
                width: 12,
                height: 12,
            },
            onItemClick: {
                toggleDataSeries: true,
            },
            onItemHover: {
                highlightDataSeries: true,
            },
        },
        colors: COLORS,
    };

    return <Chart options={options} series={seriesData} type="pie" height={size.height} />;
};

export default ApexPieChart;
