import * as React from 'react';
import {useEffect, useState} from 'react';
import {DataGrid, GridColDef} from '@mui/x-data-grid';
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {useDispatch, useSelector} from "react-redux";
import {mostReportedNumber} from "../store/actions/report_actions";


const columns: GridColDef[] = [
    { field: 'label', headerName: 'Phone', width: 290 },
    { field: 'value', headerName: 'Cases Reported', width: 170 },
    // { field: 'userComment', headerName: 'Remark ', width: 170 }
]


export default function Orders({ openForm }) {
    const dispatch = useDispatch();
    const [reload, setReload] = useState(0);
    const reported = useSelector(state => state.reports);

    useEffect(() => {
        if (reported && reported.by_phone && reported.by_phone.length < 1 && reload <= 2) {
            dispatch(mostReportedNumber());
            setReload(prevReload => prevReload + 1);
        }
    }, [dispatch, reload]);

    const formattedReports = reported?.by_phone || { phone: [], count: [] };

    const data = Array.isArray(formattedReports.phone)
        ? formattedReports.phone.map((phone, index) => {
            const id = index + 1;  // Example: You can use the index as a simple identifier
            const value = formattedReports.count[index];
            return { id, value, label: phone };
        })
        : [];


    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid my={1} container justifyContent="space-between">
                <Grid item>
                    <Typography sx={{color : "blue", fontWeight : "bold", mt : -2}} > Most Reported Number </Typography>
                </Grid>
            </Grid>
            <DataGrid rows={data} columns={columns} pageSize={5} rowsPerPageOptions={[5]} />
        </Container>
    );
}
