import axios from "axios";
import {BASE_URL} from "../URL";
import {createAsyncThunk} from "@reduxjs/toolkit";


axios.defaults.headers.post['Content-Type'] = "application/json";

const REPO_API  = axios.create({baseURL : BASE_URL });
REPO_API.interceptors.request.use((req)  => {
    const storage =  sessionStorage.getItem("mripoti-token");
    const {data}  =  JSON.parse(storage);
    const {token}  = data;

    if(token != null){
        req.headers.Authorization = `Bearer ${token}`;
    }
    return req;
})


export const getAllReported = createAsyncThunk('reports', async () =>{
     try {
          const  response =   await REPO_API.get("management/report-tapeli/get-all");
          // console.log(response.data);
         return response.data;
     }
     catch (error){
         console.log(error)
         return error.message;
     }
})

export const barGraphSummary = createAsyncThunk("graph", async(values) => {
    values = "2024"
    try {
         const response = await REPO_API.get(`management/report-tapeli/bar-graph/${values}`);

         // console.log(response.data);
         return response.data
    }
    catch (error){
        console.log(error);
        return error.message
    }
})


export const summaryByComments = createAsyncThunk("comments", async () => {
    try {
        const  response =   await REPO_API.get("report-tapeli/pie-chart");

        // console.log(response.data);
        return  response.data;
    }
    catch (error){
        console.log(error);
        return error.message;
    }
})


export const mostReportedNumber = createAsyncThunk("number", async() => {
    try {
         const response =  await  REPO_API.get("report-tapeli/by-phone");

         // console.log(response.data);
         return  response.data;
    }
    catch (error){
        console.log(error);
        return error.message;
    }
})

export const mostReportedRegion = createAsyncThunk("regions",  async () => {
    try {
          const response = await REPO_API.get("report-tapeli/by-region");

          // console.log(response.data);
          return  response.data;
    }
    catch (error){
        console.log(error);
        return error.message;
    }
})

export const regionalReport =  createAsyncThunk('regionwise', async(values) => {
    try{
        const  response  =  await REPO_API.get(`report-tapeli/get-by-location/${values}`);

        // console.log(response.data);
        return  response.data
    }
    catch (error){
        console.log(error)
        return error.message
    }
} )

export const  userCommentReport = createAsyncThunk("commentwise", async (values) =>{
    // console.log(values);
    try{
        const response =  await REPO_API.get(`report-tapeli/get-by-comments/${values}`);

        // console.log(response.data);
        return response.data;
    }
    catch (error){
        console.log(error);
        return  error.message
    }
})