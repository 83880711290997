import React, {useState} from 'react';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import report_icon from '../assets/icons/image.png';
import {useNavigate} from "react-router-dom";
import MenuItem from '@mui/material/MenuItem';
import * as Yup from 'yup';
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {getAllUsers, registerUser} from "../store/actions/user_actions";
import {useDispatch} from "react-redux";
import {Alert, Stack} from "@mui/material";

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://mui.com/">
                www.ega.go.tz
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

const Schema = Yup.object({
        firstName: Yup.string().required().trim(),
        lastName: Yup.string().required().trim(),
        email: Yup.string().required().email().trim(),
        middleName: Yup.string().trim(),
        phone: Yup.string().required().min(12).max(12).trim(),
        role: Yup.string().required().trim(),
        // Uncomment the following lines if you want to include password and confirm password fields
        // password: Yup.string().required().min(6),
        // confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
    });

export default function RegisterForm() {

    const navigate =  useNavigate();
    const dispatch = useDispatch();

    const [succeed, setSucceed]  =  useState(false);
    const [failed, setFailed] = useState(false);

    const {control, handleSubmit,reset, formState,setError,isSubmitSuccesful} =  useForm({
        resolver :yupResolver(Schema)
    });
    const onSubmit = async (data) => {

        try {

            // Dispatch the signInUser action
            const response = await dispatch(registerUser(data));
            // console.log(data)

            setTimeout(() =>{
                reset({
                    email : "",
                    firstName : "",
                    lastName :  "",
                    middleName : "",
                    phone : "",
                    role : ""
                })
            },500)

            const newToken = response.payload;
            // console.log(newToken);
            if (newToken?.data?.active) {
                setSucceed(true)
                setFailed(false)

                setTimeout(() => {
                    // navigate('/dashboard');
                    dispatch(getAllUsers())
                    setSucceed(false);
                },3000)

            } else {
                setFailed(true)
                setSucceed(false);

                setTimeout(() => {
                    setFailed(false)
                },4000)
            }
        } catch (error){
            console.log(error)
        }



    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="md">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >

                    <Stack>
                        {
                            succeed ? <Alert severity="success">User Created Successful.</Alert> : <></>
                        }
                        {
                            failed ? <Alert severity="error">Request failed please try again.</Alert>  : <></>
                        }

                    </Stack>

                    <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                        <img src={report_icon} style={{width: '100%', height: '100%', objectFit: 'cover'}}/>
                    </Avatar>

                    <Typography component="h1" variant="h5">
                        Register User
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="firstName"
                                    control={control}
                                    defaultValue=""
                                    render={({field,fieldState})=>(
                                        <TextField
                                            {...field}
                                            autoComplete="given-name"
                                            name="firstName"
                                            required
                                            fullWidth
                                            id="firstName"
                                            label="First Name"
                                            autoFocus
                                            error={Boolean(fieldState.error)}
                                            helperText={fieldState.error?.message}
                                        />
                                    )}
                                     />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="lastName"
                                    control={control}
                                    defaultValue=""
                                    render={({field,fieldState}) =>(
                                        <TextField
                                            {...field}
                                            required
                                            fullWidth
                                            id="lastName"
                                            label="Last Name"
                                            autoComplete="family-name"
                                            autoFocus
                                            error={Boolean(fieldState.error)}
                                            helperText={fieldState.error?.message}
                                        />
                                    )}
                                       />
                            </Grid>

                            <Grid container spacing={2} my={2} ml={0.5}>
                                <Grid item xs={12} sm={6}>
                                    <Controller
                                        name="middleName"
                                        control={control}
                                        defaultValue=""
                                        render={({field, fieldState})=>(
                                            <TextField
                                                {...field}
                                                autoComplete="given-name"
                                                required
                                                fullWidth
                                                label="Middle Name"
                                                autoFocus
                                                error={Boolean(fieldState.error)}
                                                helperText={fieldState.error?.message}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Controller
                                        name="role"
                                        control={control}
                                        defaultValue=""
                                        render={({ field,fieldState }) =>(
                                            <TextField
                                                {...field}
                                                required
                                                fullWidth
                                                id="userRole"
                                                label="User Role"
                                                select  // Add select prop to indicate it's a select input
                                                autoFocus
                                                autoComplete="family-name"
                                                error={Boolean(fieldState.error)}
                                                helperText={fieldState.error?.message}
                                            >
                                                <MenuItem value="ADMIN">ADMIN</MenuItem>
                                                <MenuItem value="SUPER_ADMIN">SUPER_ADMIN</MenuItem>
                                            </TextField>
                                        )}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} my={2} ml={1} >
                                <Grid item xs={12} sm={6} mt={-2} >
                                    <Controller
                                        name="email"
                                        control={control}
                                        defaultValue=""
                                        render={({field, fieldState}) =>(
                                            <TextField
                                                {...field}
                                                margin="normal"
                                                required
                                                fullWidth
                                                label="Email Address"
                                                autoComplete="email"
                                                autoFocus
                                                error={Boolean(fieldState.error)}
                                                helperText={fieldState.error?.message}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} >
                                    <Controller
                                        name="phone"
                                        control={control}
                                        defaultValue=""
                                        render={({field,fieldState}) =>(
                                            <TextField
                                                {...field}
                                                required
                                                fullWidth
                                                id="phone"
                                                label="Telephone"
                                                autoComplete="telephone"
                                                autoFocus
                                                error={Boolean(fieldState.error)}
                                                helperText={fieldState.error?.message}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>

                                </Grid>



                            {/*<Grid container spacing={1} my={2} ml={1} >*/}
                            {/*    <Grid item xs={12} sm={6}>*/}
                            {/*        <TextField*/}
                            {/*            required*/}
                            {/*            fullWidth*/}
                            {/*            name="password"*/}
                            {/*            label="Password"*/}
                            {/*            type="password"*/}
                            {/*            id="password"*/}
                            {/*            autoComplete="new-password"*/}
                            {/*        />*/}
                            {/*    </Grid>*/}
                            {/*    <Grid item xs={12}  sm={6} >*/}
                            {/*        <TextField*/}
                            {/*            required*/}
                            {/*            fullWidth*/}
                            {/*            name="password"*/}
                            {/*            label="Confirm Password"*/}
                            {/*            type="password"*/}
                            {/*            id="password"*/}
                            {/*            autoComplete="new-password"*/}
                            {/*        />*/}
                            {/*    </Grid>*/}
                            {/*</Grid>*/}


                            <Grid item xs={12}>

                            </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Register
                        </Button>
                        <Grid container justifyContent="flex-end">

                        </Grid>
                        {/*</Grid>*/}

                    </Box>
                </Box>
                <Copyright sx={{ mt: 5 }} />
            </Container>
        </ThemeProvider>
    );
}
