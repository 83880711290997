import * as React from 'react';
import {useEffect, useState} from 'react';
import {DataGrid, GridColDef} from '@mui/x-data-grid';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {Add} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import {getAllUsers} from "../store/actions/user_actions";
import {useNavigate} from "react-router-dom";

const columns: GridColDef[] = [
    { field: 'firstName', headerName: 'Firstname', width: 200 },
    { field: 'lastName', headerName: 'Lastname', width: 200 },
    { field: 'username', headerName: 'Username', width: 200 },
    { field: 'phone', headerName: 'Telephone', width: 200 },
    { field: 'userRole', headerName: 'Role', width: 200 },
    // {
    //     field: 'col6',
    //     headerName: 'Actions',
    //     width: 190,
    //     renderCell: (params) => (
    //         <Grid container flexDirection="horizontal" justifyContent="space-between">
    //             <Grid item>
    //                 <Button
    //                     startIcon={<EditIcon />}
    //                     size="small"
    //                     justifyContent="space-between"
    //                     variant="contained"
    //                     color="primary"
    //                     onClick={() => handleEditClick(params.row.id)}
    //                 >
    //                     Edit
    //                 </Button>
    //             </Grid>
    //             <Grid item>
    //                 <Button
    //                     endIcon={<DeleteIcon />}
    //                     size="small"
    //                     variant="contained"
    //                     color="secondary"
    //                     onClick={() => handleDeleteClick(params.row.id)}
    //                 >
    //                     Delete
    //                 </Button>
    //             </Grid>
    //         </Grid>
    //     ),
    // },
];

export const handleEditClick = (userId, navigate) => {
    // Perform custom action for Edit button click
    console.log(`Edit button clicked for user with ID ${userId}`);
    // Navigate to "my-profile"
    navigate('/my-profile');
};

const handleDeleteClick = (userId) => {
    // Perform custom action for Delete button click
    console.log(`Delete button clicked for user with ID ${userId}`);
};

export default function UsersData({ openForm }) {
    const dispatch = useDispatch();
    const [reload, setReload] = useState(0);
    const all_users = useSelector(state => state.users);
    const navigate = useNavigate();

    useEffect(() => {
        if (all_users && all_users.all_users && all_users.all_users.length < 1 && reload <= 2) {
            dispatch(getAllUsers());
            setReload(prevReload => prevReload + 1);
        }
    }, [dispatch, reload]);

    const rows = all_users?.all_users?.content || [];

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid my={2} container justifyContent="space-between">
                <Grid item>
                    <Typography sx={{color : 'blue', fontWeight : 'bold'}} > Registered Users </Typography>
                </Grid>
                <Grid item>
                    <Button startIcon={<Add />} variant="contained" color="primary" onClick={() => openForm()}>
                        Add User
                    </Button>
                </Grid>
            </Grid>

            {/* Check if rows is an array before rendering DataGrid */}
            {Array.isArray(rows) && (
                <DataGrid rows={rows} columns={columns} />
            )}
        </Container>
    );
}
