import * as React from 'react';
import {useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import report_icon2 from '../assets/icons/analytics.gif';

import {useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {useDispatch} from 'react-redux';
import {loginUser} from '../store/actions/user_actions';
import {Alert, Stack} from '@mui/material';

function Copyright(props) {
    return (
        <Typography variant="body2" fontWeight="bold" color="white" align="center" {...props}>
            {'Copyright © '}
            <Link sx={{ color: 'white' }} href="https://mui.com/">
                www.ega.go.tz
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const Schema = Yup.object({
    email: Yup.string().required().email().trim(),
    password: Yup.string().required().trim(),
});

const defaultTheme = createTheme();

export default function SignIn() {
    const { control, handleSubmit, reset, formState, setError, isSubmitSuccesful } = useForm({
        resolver: yupResolver(Schema),
    });

    const dispatch = useDispatch();
    const [succeed, setSucceed] = useState(false);
    const [failed, setFailed] = useState(false);

    const onSubmit = async (data) => {
        try {
            // Clear existing token
            sessionStorage.removeItem('mripoti-token');

            // Dispatch the signInUser action
            const response = await dispatch(loginUser(data));

            setTimeout(() => {
                reset({
                    email: '',
                    password: '',
                });
            }, 500);

            const newToken = response.payload;
            // console.log(newToken);
            if (newToken?.token) {
                setSucceed(true);
                setFailed(false);

                setTimeout(() => {
                    navigate('/dashboard');
                    setSucceed(false);
                }, 3000);
            } else {
                setFailed(true);
                setSucceed(false);

                setTimeout(() => {
                    setFailed(false);
                }, 4000);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const navigate = useNavigate();

    return (
        <ThemeProvider theme={defaultTheme}>
            <div
                style={{
                    width: '100%',
                    height: '100vh',
                    // background: `url(${bg_image}) center/cover no-repeat`, // Replace with your image path
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: 0,
                    padding: 10,
                    position: 'relative',
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0 0.6)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Container component="main" maxWidth="xs">
                        <CssBaseline />
                        <Box
                            sx={{
                                marginTop: 5,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                boxShadow: 4,
                                padding: 4,
                                backgroundColor: 'white',
                                borderRadius: 2,
                                width: '100%', // Updated to 100%
                                maxWidth: '400px', // Added max-width for better responsiveness
                            }}
                        >
                            <Stack>
                                {succeed ? <Alert severity="success">Login Successful.</Alert> : <></>}
                                {failed ? <Alert severity="error">Login failed. Please try again.</Alert> : <></>}
                            </Stack>
                            <Avatar sx={{ m: 1, width: 64, height: 64, bg: 'gray' }}>
                                <img src={report_icon2} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                            </Avatar>
                            <Typography component="h1" variant="h5" sx={{ color: '#0766AD', fontWeight: 'bold' }}>
                                Mripoti Tapeli
                            </Typography>
                            <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1, width: '100%' }}>
                                <Controller
                                    name="email"
                                    control={control}
                                    defaultValue=""
                                    render={({field, fieldState}) => (
                                        <TextField
                                            {...field}
                                            margin="normal"
                                            required
                                            fullWidth
                                            label="Email Address"
                                            autoComplete="email"
                                            autoFocus
                                            error={Boolean(fieldState.error)}
                                            helperText={fieldState.error?.message}
                                        />
                                    )}
                                />

                                <Controller
                                    name="password"
                                    control={control}
                                    defaultValue=""
                                    render={({field, fieldState}) => (
                                        <TextField
                                            {...field}
                                            margin="normal"
                                            required
                                            fullWidth
                                            label="Password"
                                            type="password"
                                            id="password"
                                            autoComplete="current-password"
                                            error={Boolean(fieldState.error)}
                                            helperText={fieldState.error?.message}
                                        />

                                    )}
                                />


                                <Grid container>

                                </Grid>

                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{mt: 3, mb: 2, backgroundColor: '#1769aa'}}

                                >
                                    Sign In
                                </Button>

                            </Box>
                        </Box>
                        <Copyright sx={{ mt: 4, mb: 4, marginHorizontal: 'auto' }} />
                    </Container>
                </div>
            </div>
        </ThemeProvider>
    );
}
