import {createSlice} from "@reduxjs/toolkit";
import {
    barGraphSummary,
    getAllReported,
    mostReportedNumber,
    mostReportedRegion,
    regionalReport,
    summaryByComments,
    userCommentReport
} from "../actions/report_actions";


const reportSlice = createSlice({
    name : "reports",
    initialState : {
        new_reports : null,
        all_reports : [],
        bar_graph : [],
        by_phone : [],
        by_comments : [],
        by_region : [],
        region_wise : [],
        comment_wise : [],
        current_report : null,
        message : "",
        error :  null,
        status : ""
    },

    reducers : {
        all_reported :  (state,action) => {
            state.all_reports.push(action.payload);
        },

        new_report : (state,action)  => {
            state.new_report.push(action.payload);
        },
        this_report : (state,action) => {
            state.current_report.push(action.payload);
        }
    },

    extraReducers(builder){
        builder
            .addCase(getAllReported.pending, (state, action) =>{
                state.status = "Loading";
            })
            .addCase(getAllReported.fulfilled, (state, action) => {
                state.status = "Successful";
                state.all_reports = action.payload;

            })
            .addCase(getAllReported.rejected, (state, action) => {
                state.status = "Failed";
                state.message = "Request failed please try again!";
                state.error = true
            })
            .addCase(barGraphSummary.pending, (state, action) => {
                state.status = "Loading"
            })
            .addCase(barGraphSummary.fulfilled, (state, action) => {
                state.status = "Successful";
                state.bar_graph = action.payload;
            })
            .addCase(barGraphSummary.rejected, (state, action) => {
                state.status = "Failed";
                state.message = "Request failed please try again!";
                state.error = true
            })
            .addCase(summaryByComments.pending, (state, action) => {
                state.status = "Loading"
            })
            .addCase(summaryByComments.fulfilled, (state, action) => {
                state.status = "Successful";
                state.by_comments = action.payload;
            })
            .addCase(summaryByComments.rejected, (state, action) => {
                state.status = "Failed";
                state.message = "Request failed please try again!";
                state.error = true
            })
            .addCase(mostReportedRegion.pending, (state, action) => {
                state.status = "Loading"
            })
            .addCase(mostReportedRegion.fulfilled, (state, action) => {
                state.status = "Successful";
                state.by_region = action.payload;
            })
            .addCase(mostReportedRegion.rejected, (state, action) => {
                state.status = "Failed";
                state.message = "Request failed please try again!";
                state.error = true
            })
            .addCase(mostReportedNumber.pending, (state, action) => {
                state.status = "Loading"
            })
            .addCase(mostReportedNumber.fulfilled, (state, action) => {
                state.status = "Successful";
                state.by_phone = action.payload;
            })
            .addCase(mostReportedNumber.rejected, (state, action) => {
                state.status = "Failed";
                state.message = "Request failed please try again!";
                state.error = true
            })
            .addCase(userCommentReport.pending, (state, action) => {
                state.status = "Loading"
            })
            .addCase(userCommentReport.fulfilled, (state, action) => {
                state.status = "Successful";
                state.comment_wise = action.payload;
            })
            .addCase(userCommentReport.rejected, (state, action) => {
                state.status = "Failed";
                state.message = "Request failed please try again!";
                state.error = true
            })
            .addCase(regionalReport.pending, (state, action) => {
                state.status = "Loading"
            })
            .addCase(regionalReport.fulfilled, (state, action) => {
                state.status = "Successful";
                state.region_wise = action.payload;
            })
            .addCase(regionalReport.rejected, (state, action) => {
                state.status = "Failed";
                state.message = "Request failed please try again!";
                state.error = true
            })
    }

})


export const  { all_reported, new_report, this_report } =   reportSlice.actions;
export default  reportSlice.reducer;