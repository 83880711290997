import React from 'react';
import Avatar from '@mui/material/Avatar';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

const UserProfile = () => {
    return (
        <div style={{ flexGrow: 1, padding: '16px' }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper style={{ padding: '16px', textAlign: 'center', color: 'text.secondary' }}>
                        <Avatar
                            alt="User Avatar"
                            src="/path/to/avatar.jpg"
                            sx={{ width: '96px', height: '96px', margin: '0 auto' }}
                        />
                        <Typography variant="h5" sx={{ marginTop: '8px' }}>
                            John Doe
                        </Typography>
                        <Typography variant="subtitle1" sx={{ marginTop: '4px' }}>
                            Web Developer
                        </Typography>
                        <Button variant="outlined" color="primary" sx={{ marginTop: '8px' }}>
                            Edit Profile
                        </Button>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper style={{ padding: '16px', textAlign: 'center', color: 'text.secondary' }}>
                        <Typography variant="h6">About Me</Typography>
                        <Typography variant="body1">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus
                            ante dapibus diam.
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper style={{ padding: '16px', textAlign: 'center', color: 'text.secondary' }}>
                        <Typography variant="h6">Skills</Typography>
                        <Typography variant="body1">JavaScript, React, Node.js, HTML, CSS</Typography>
                    </Paper>
                </Grid>
                {/* Add more sections as needed */}
            </Grid>
        </div>
    );
};

export default UserProfile;
