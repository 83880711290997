import React, {useState} from 'react';

import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import report_icon from '../assets/icons/image.png';
import {useNavigate} from "react-router-dom";
import CustomDrawer from "../util/Drawer";
import CustomAppBar from "../util/AppBar";
import Toolbar from "@mui/material/Toolbar";
import * as Yup from 'yup'
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useDispatch} from "react-redux";
import {changePassword} from "../store/actions/user_actions";
import Button from "@mui/material/Button";
import {Alert, Stack} from "@mui/material";

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://mui.com/">
                www.ega.go.tz
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

const Schema = Yup.object({
    oldPassword : Yup
        .string()
        .required()
        .trim(),
    newPassword : Yup
        .string()
        .required()
        .min(8)
        .trim(),
    confirmPassword : Yup
        .string()
        .required()
        .min(8)
        .trim()
})

export default function ChangePassword() {

    const [open, setOpen] = useState(true);

    const toggleDrawer = () => {
        setOpen(!open);
    };


    const navigate =  useNavigate();
    const {control, handleSubmit,reset, formState,setError,isSubmitSuccesful} =  useForm({
        resolver :yupResolver(Schema)
    });


    const dispatch = useDispatch();
    const [succeed, setSucceed]  =  useState(false);
    const [failed, setFailed] = useState(false);

    const onSubmit = async (data) => {

        try {

            // Dispatch the signInUser action
            const response = await dispatch(changePassword(data));

            setTimeout(() =>{
                reset({
                    email : "",
                    password : ""
                })
            },500)

            const newToken = response.payload;
            // console.log(newToken);
            if (newToken?.token) {
                setSucceed(true)
                setFailed(false)

                setTimeout(() => {
                    navigate('/dashboard');
                    setSucceed(false);
                },3000)

            } else {
                setFailed(true)
                setSucceed(false);

                setTimeout(() => {
                    setFailed(false)
                },4000)
            }
        } catch (error){
            console.log(error)
        }



    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />

                <CustomDrawer open={open} toggleDrawer={toggleDrawer} />
                <CustomAppBar open={open} toggleDrawer={toggleDrawer}  />
                {/* ... (rest of your code) */}

                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar />

            <Container component="main" maxWidth="sm">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        boxShadow: 4,
                        padding :4,
                        borderRadius : 2,
                    }}
                >

                    <Stack>
                        {
                            succeed ? <Alert severity="success">login Successful.</Alert> : <></>
                        }
                        {
                            failed ? <Alert severity="error">login failed please try again.</Alert> : <></>
                        }

                    </Stack>

                    <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                        <img src={report_icon} style={{width: '100%', height: '100%', objectFit: 'cover'}}/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Change Password
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>

                        <Controller
                            name="oldPassword"
                            control={control}
                            defaultValue=""
                            render={({field, fieldState}) => (
                                <TextField
                                    {...field}
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="Old Password"
                                    type="password"
                                    id="oldpassword"
                                    autoComplete="current-password"
                                    error={Boolean(fieldState.error)}
                                    helperText={fieldState.error?.message}
                                />

                            )}
                        />


                        <Controller
                            name="newPassword"
                            control={control}
                            defaultValue=""
                            render={({field, fieldState}) => (
                                <TextField
                                    {...field}
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="New Password"
                                    type="password"
                                    id="newpassword"
                                    autoComplete="current-password"
                                    error={Boolean(fieldState.error)}
                                    helperText={fieldState.error?.message}
                                />

                            )}
                        />

                        <Controller
                            name="confirmPassword"
                            control={control}
                            defaultValue=""
                            render={({field, fieldState}) => (
                                <TextField
                                    {...field}
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="Confirm Password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    error={Boolean(fieldState.error)}
                                    helperText={fieldState.error?.message}
                                />

                            )}
                        />

                        <Grid item xs={12}>

                            </Grid>
                        {/*</Grid>*/}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Update
                        </Button>

                    </Box>
                </Box>
                <Copyright sx={{ mt: 5 }} />
            </Container>
                </Box>
                </Box>
        </ThemeProvider>
    );
}
