// Drawer.js
import React from 'react';
import {styled} from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {useNavigate} from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ListItemText from "@mui/material/ListItemText";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import {Logout} from "@mui/icons-material";
import {useDispatch} from "react-redux";


const drawerWidth = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
            backgroundColor: '#1769aa', // Set the background color to navy blue
            color: 'white', // Set text and icons color to white
        },
    })
);

const CustomDrawer = ({ open, toggleDrawer }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const  handleLogout = () => {
        sessionStorage.removeItem("mripoti-token");
        navigate("/")
    }

    return (
        <Drawer variant="permanent" open={open}>
            {/* ... (rest of your Drawer content) */}
            <Toolbar
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    backgroundColor : '#1769aa',
                    px: [1],
                }}
            >

                <Typography
                    component="h1"
                    variant="h6"
                    color="inherit"
                    pl={2}
                    noWrap
                    sx={{ flexGrow: 1 }}
                >
                    MRIPOTI TAPELI
                </Typography>

                <IconButton onClick={toggleDrawer}>
                    {
                        open? <ChevronLeftIcon sx={{ color: 'white' }} /> : <ChevronRightIcon sx={{ color: 'white' }} />
                    }

                </IconButton>
            </Toolbar>
            <Divider />

            <List component="nav" >

                <ListItemButton  onClick={() => navigate("/dashboard")} >
                    <ListItemIcon sx={{ color: 'white' }}>
                        <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                </ListItemButton>
                <ListItemButton onClick={() => navigate("/users")} >
                    <ListItemIcon sx={{ color: 'white' }}>
                        <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Users"   />
                </ListItemButton>
                <ListItemButton onClick={() => navigate("/reports")} >
                    <ListItemIcon sx={{ color: 'white' }} >
                        <BarChartIcon />
                    </ListItemIcon>
                    <ListItemText primary="Reports" />
                </ListItemButton>

                 {/*<ListItemButton onClick={() => navigate("/change-password") }>*/}
                 {/*    <ListItemIcon sx={{ color: 'white' }} >*/}
                 {/*      <Person />*/}
                 {/*      </ListItemIcon>*/}
                 {/*      <ListItemText primary="Change Password" />*/}
                 {/*</ListItemButton>*/}
                <ListItemButton onClick={handleLogout} >
                    <ListItemIcon sx={{ color: 'red' }} >
                        <Logout />
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                </ListItemButton>

            </List>
            <Divider sx={{ my: 1 }} />
            {/* {secondaryListItems} */}
        </Drawer>
    );
};

export default CustomDrawer;

