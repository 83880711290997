import React, {useEffect, useState} from 'react';
import Chart from 'react-apexcharts';
import {useDispatch, useSelector} from 'react-redux';
import {mostReportedRegion, regionalReport} from '../store/actions/report_actions';
import {useNavigate} from "react-router-dom";

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AF19FF'];
const size = {
    width: 400,
    height: 400,
};

const RegionalCharts = () => {
    const [reload, setReload] = useState(0);
    const dispatch = useDispatch();
    const pie_chart = useSelector((state) => state.reports);
    const navigate  = useNavigate();

    useEffect(() => {
        if (pie_chart && pie_chart.by_region && pie_chart.by_region.length < 1 && reload <= 2) {
            dispatch(mostReportedRegion());
            setReload((prevReload) => prevReload + 1);
        }
    }, [reload, pie_chart,dispatch]);

    const changePage = (label) => {

        dispatch( regionalReport(label))
        navigate("/by-location", {state : {label}})
    }

    const formattedReports = pie_chart?.by_region || { region: [], count: [] };

    const seriesData = Array.isArray(formattedReports.count) ? formattedReports.count : [];

    const options = {
        chart: {
            type: 'bar',
            events: {
                dataPointSelection: (event, chartContext, config) => {
                    const selectedDataPoint = config.dataPointIndex;
                    const selectedLabel = formattedReports.region?.[selectedDataPoint];

                    // console.log('Selected Label:', selectedLabel);
                    changePage(selectedLabel)
                },
            },
        },
        xaxis: {
            categories: formattedReports.region || [],
            labels: {
                rotate: -45,
                style: {
                    colors: COLORS[0],
                    fontSize: '12px',
                    fontWeight: 'bold',
                },
            },
        },

        colors: COLORS,
    };

    return <Chart options={options} series={[{ data: seriesData }]} type="bar" height={size.height} />;

};

export default RegionalCharts;
