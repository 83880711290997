import * as React from 'react';
import {useEffect, useState} from 'react';
import {DataGrid, GridColDef} from '@mui/x-data-grid';
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import {userCommentReport} from "../store/actions/report_actions";

const columns: GridColDef[] = [
    { field: 'formattedDate', headerName: 'Date', width: 170 },
    { field: 'message', headerName: 'Message', width: 640 },
    { field: 'phone', headerName: 'Sent By', width: 170 },
    { field: 'userComment', headerName: 'User Comment', width: 170 },
    { field: 'location', headerName: 'Reported From', width: 170 },
    // {
    //     field: 'col6',
    //     headerName: 'Actions',
    //     width: 190,
    //     renderCell: (params) => (
    //         <Grid container flexDirection="horizontal" justifyContent="space-between">
    //             <Grid item>
    //                 <Button
    //                     startIcon={<Preview />}
    //                     size="small"
    //                     justifyContent="space-between"
    //                     variant="contained"
    //                     color="primary"
    //                     onClick={() => handleEditClick(params.row.id)}
    //                 >
    //                     View
    //                 </Button>
    //             </Grid>
    //         </Grid>
    //     ),
    // },
];

const handleEditClick = (userId) => {
    // Perform custom action for Edit button click
    console.log(`Edit button clicked for user with ID ${userId}`);
};

export default function SelectedData({comment,label}) {
    const dispatch = useDispatch();
    const [reload, setReload] = useState(0);
    const reported = useSelector(state => state.reports);

    // console.log(comment)

    useEffect(() => {
        if (reported && reported.comment_wise && reported.comment_wise.length < 1 && reload <= 2) {
            dispatch(userCommentReport(comment));
            setReload(prevReload => prevReload + 1);
        }
    }, [dispatch, reload]);

    const formattedReports = reported?.comment_wise?.content || [];

    // Check if formattedReports is an array before mapping over it
    const rows = Array.isArray(formattedReports) ? formattedReports.map(report => ({
        ...report,
        formattedDate: moment(report.createdAt).format("ll"),
    })) : [];

    return (
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Grid my={2} container justifyContent="space-between">
                <Grid item>
                    <Typography sx={{color : 'blue', fontWeight : 'bold'}} > Reported Messages  -  {comment}  </Typography>
                </Grid>
            </Grid>
            <DataGrid rows={rows} columns={columns} />
        </Container>
    );
}
