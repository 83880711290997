import {createSlice} from "@reduxjs/toolkit";
import {changePassword, getAllUsers, loginUser, registerUser} from "../actions/user_actions";


export const UserSlice = createSlice({
    name : "users",
    initialState : {
        loged_user : null,
        current_user  : null,
        new_user : null,
        all_users : [],
        password_change : null,
        status : '',
        error :   null,
        message : ""
    },


    reducers : {
        create_user : (state,action) => {
            state.new_user().push(action.payload)
        },
        allUsers  : (state,action) => {
            state.all_users.push(action.payload)
        },
        signIn : (state,action) => {
            state.loged_user.push(action.payload)
        }

    },

    extraReducers (builder){
        builder
            .addCase(loginUser.pending, (state, action) => {
                state.status = "Loading"
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.status = "Successful";
                state.message = action.payload.message;
                state.loged_user = action.payload;
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.status = "Failed";
                state.message = action.error.message;
                state.error = true;
            })
            .addCase(registerUser.pending, (state, action) => {
                state.status = "Loading";
            })
            .addCase(registerUser.fulfilled, (state, action) => {
                state.status = "Successful";
                state.message = action.payload.message;
                state.new_user = action.payload
            })
            .addCase(registerUser.rejected, (state, action) => {
                state.status = "Failed";
                state.message = action.error.message;
                state.error = true;
            })
            .addCase(getAllUsers.pending, (state, action) => {
                state.status ="Loading"
            })
            .addCase(getAllUsers.fulfilled, (state, action) => {
                state.status = "Successful";
                state.all_users = action.payload;
            })
            .addCase(getAllUsers.rejected, (state, action) => {
                state.status = "Failed";
                state.message = action.error.message;
                state.error = true;
            })
            .addCase(changePassword.pending, (state, action) => {
                state.status ="Loading"
            })
            .addCase(changePassword.fulfilled, (state, action) => {
                state.status = "Successful";
                state.password_change = action.payload;
            })
            .addCase(changePassword.rejected, (state, action) => {
                state.status = "Failed";
                state.message = action.error.message;
                state.error = true;
            })


    }
})


export const { create_user, allUsers, signIn } = UserSlice.actions;
export default  UserSlice.reducer;