import * as React from 'react';
import {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {BarChart} from '@mui/x-charts/BarChart';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {useDispatch, useSelector} from "react-redux";
import {barGraphSummary} from "../store/actions/report_actions";
import {axisClasses} from '@mui/x-charts';

const theme = createTheme({
  overrides: {
    MuiBarChart: {
      root: {
        backgroundColor: '#001F3F', // Navy blue background color
      },
    },
  },
});


const chartSetting = {
  yAxis: [
    {
      label: 'rainfall (mm)',
    },
  ],
  width: 500,
  height: 300,
  sx: {
    [`.${axisClasses.left} .${axisClasses.label}`]: {
      transform: 'translate(-20px, 0)',
    },
  },
};

const valueFormatter = (value) => `${value}mm`;

const highlightScope = {
  highlighted: 'series',
  faded: 'global',
};

export default function BarAnimation() {
  const [skipAnimation, setSkipAnimation] = React.useState(false);
  const monthNames = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  const [reload, setReload] = useState(0);
  const dispatch = useDispatch();
  const bar_graph = useSelector(state => state.reports);

  useEffect(() => {
    if (bar_graph && bar_graph.bar_graph && bar_graph.bar_graph.length < 1 && reload <= 2) {
      dispatch(barGraphSummary('2024'));
      setReload(prevReload => prevReload + 1);
    }
  }, [reload, bar_graph]);

  const formattedReports = bar_graph?.bar_graph?.count || [10, 0, 10, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  // Map data to fit the dataset and series props
  const dataset = formattedReports.map((value, index) => ({
    month: monthNames[index],
    london: value,
  }));

  const series = [
    { dataKey: 'london', label: 'Annual Report', valueFormatter },
  ];

  return (
      <ThemeProvider theme={theme}>
        <Box sx={{ width: '100%', pl:4 }}>
          <BarChart
              dataset={dataset}
              xAxis={[{ scaleType: 'band', dataKey: 'month' }]}
              series={series}
              {...chartSetting}
          />
          <Typography id="input-item-number" gutterBottom>
            Number of items
          </Typography>

          <Typography id="input-series-number" gutterBottom>
            Number of series
          </Typography>
        </Box>
      </ThemeProvider>
  );
}
