import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './index.css';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Dashboard from './Pages/Dashboard';
import Users from "./Pages/Users";
import Reported from "./Pages/Reported";
import ProtectedRoute from "./context/Protect";
import {AuthProvider} from "./context";
import ChangePassword from "./Pages/ChangePassword";
import UserProfile from "./Pages/UserProfile";
import CommentWise from "./Pages/CommentWise";
import RegionalWise from "./Pages/RegionalWise";
import Home from "./Pages/Home";

function App() {
  return (
    <BrowserRouter>
        <AuthProvider>
      <Routes>
        {/*<Route path="/"  element={<SignIn  />} />*/}
        <Route path="/" element={<Home />}  />
        <Route path="/dashboard" element={ <ProtectedRoute> <Dashboard  />  </ProtectedRoute> }  />
        <Route path="/users" element={ <ProtectedRoute> <Users /> </ProtectedRoute> }  />
        <Route path="/reports" element={ <ProtectedRoute> <Reported  />  </ProtectedRoute> }  />
        <Route path="/change-password"  element={ <ProtectedRoute> < ChangePassword /> </ProtectedRoute> } />
          <Route path="my-profile" element={<ProtectedRoute> <UserProfile />  </ProtectedRoute>} />
        <Route path="by-comments" element={<ProtectedRoute> <CommentWise /> </ProtectedRoute>}  />
        <Route path="by-location" element={<ProtectedRoute> <RegionalWise  /> </ProtectedRoute>}   />
      </Routes>
        </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
